<script>
import {required} from "vuelidate/lib/validators";
import HttpRequest from "@/http/HttpRequest"

import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

let httpRequest = new HttpRequest()

export default {
  data() {
    return {
      password: "",
      password_again: "",
      submitted: false,
      user_id: "",
      auth_data: "",
      client_id: "",
      token: ""
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.user_id = this.$route.query.id
    this.auth_data = this.$route.query.auth_data
    this.client_id = this.$route.query.client_id
    this.token = this.$route.query.token
  },
  validations: {
    password_again: {required},
    password: {required}
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    doLogin() {

      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;

        // All right: Go for login api request
      } else {

        let _this = this

        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots"

        });

        // Init sweet alert2
        let swal = this.$swal
        if (_this.password === _this.password_again) {
          httpRequest.resetPassword(this.user_id, this.auth_data, this.client_id, this.token, this.password).then(function (response) {

            loader.hide()
            if (response.success) {
              swal("Password Changed!", "Your password has been changed successfully", "success").then(value => {
                if (value) {
                  _this.$router.push({path: "/"});
                }
              })

            } else {
              swal(response.message);
            }
          })
        } else {
          loader.hide()
          swal('Password Mismatched!',"Please make sure your passwords match","error");
        }

      }

    }
  }
};

</script>

<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-lg-5 col-xl-4" style="background-color: #edeeff">
        <div class="form-page-content p-4 d-flex align-items-center flex-column justify-content-center min-vh-100">
          <div class="text-center">
            <div>
              <a href="/" class="logo">
                <img src="@/assets/images/workany-v-logo.png" height="130" width="180" alt="WorkAny"/>
              </a>
            </div>
            <h4 class="font-size-18 mt-3 mt-md-4">Please reset password to continue.</h4>
          </div>

          <b-alert
              variant="danger"
              class="mt-3"
              v-if="notification.message"
              show
              dismissible
          >{{ notification.message }}
          </b-alert>

          <div class="p-2 mt-3 mt-md-5 w-100" style="max-width: 440px">
            <form class="form-horizontal" @submit.prevent="doLogin">
              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="password">Password</label>
                <input
                    type="password"
                    v-model="password"
                    class="form-control"
                    id="password"
                    placeholder="Enter password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                />

              </div>

              <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">Password Again</label>
                <input
                    v-model="password_again"
                    type="password"
                    class="form-control"
                    id="userpassword"
                    placeholder="Enter password again"
                    :class="{ 'is-invalid': submitted && $v.password_again.$error }"
                />
                <div
                    v-if="submitted && !$v.password_again.required"
                    class="invalid-feedback"
                > Password is required.
                </div>
              </div>
              <div class="mb-3 mb-md-0 text-center">
                <button class="btn btn-primary w-md waves-effect waves-light customs-bg-color" type="submit">SUBMIT</button>
              </div>
            </form>
          </div>

          <div class="mt-3 mt-md-4 text-center position-absolute fixed-bottom">
            <div style="border-bottom: 1px solid #ddd "></div>
            <p class="mt-3 mt-md-4 text-center">© 2023 WORKANY. Crafted by <a href="https://w3engineers.com/" class="text-primary" target="_blank">W3
              Engineers Ltd</a>.</p>
          </div>

        </div>
      </div>
      <div class="col-lg-7 col-xl-8 d-none d-lg-block">
        <div class="min-vh-100 py-5 d-flex align-items-center">
          <div class="w-100">
            <h4 class="font-size-28 text-center px-3">Spend less time tracking and more time growing.</h4>
            <p class="text-center px-3">The all-in-one work time tracker for managing field or remote teams.</p>
            <div class="d-flex justify-content-center">
              <img src="@/assets/images/bg/home.jpg" height="100%" width="70%" alt="change password"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customs-bg-color{
  background-color: #4B1FE5 !important;
}
</style>
